import {
  HTMLAttributes,
  Key,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ClickAwayListener, Popper, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import maxSize from 'popper-max-size-modifier';

import {
  CriteriaSearch,
  SearchInCriteriaProps,
} from './_components/CriteriaSearch';
import { Options, OptionsListProps } from './_components/Options';
import { RecentSearch, RecentSearchProps } from './_components/RecentSearch';
import { Criterion } from './types';
import { dropdownOptionsSort } from './utils';
import { mergeSx } from '../../utils';
import {
  AutocompleteOverrides,
  ChipInputForwardRef,
  DropdownProps,
  InputChipProps,
  useDropdownWithAutocomplete,
} from '../controls';
import { ChipInput, ChipInputProps } from '../controls/chip-input';

const Root = styled('div', {
  name: 'DsSearch',
  slot: 'Root',
  target: 'DsSearch-Root',
  overridesResolver: (_, styles) => [styles.root],
})``;

const NotFound = styled('div', {
  name: 'DsSearch',
  slot: 'NotFound',
  target: 'DsSearch-NotFound',
  overridesResolver: (_, styles) => [styles.notFound],
})<HTMLAttributes<HTMLDivElement>>``;

type Actions = {
  onApply: MouseEventHandler;
  onClearAll: MouseEventHandler;
};

export type SearchProps<OptionItem, OptionValue> = {
  rootSx?: SxProps;
  inputChipSx?: SxProps;
  listHeight?: number;
  searchWhileActive?: boolean;
  disableFreeText?: boolean;
  disabled?: boolean;
  chipInputProps?: Omit<
    ChipInputProps<InputChipProps>,
    'onChange' | 'onBlur' | 'value'
  >;
  dropdownProps: DropdownProps<OptionItem>;
  autocompleteProps: Partial<AutocompleteOverrides<OptionItem, OptionValue>>;
  recentSearchProps?: RecentSearchProps;
  criteriaSearchProps?: SearchInCriteriaProps;
  activeCriterion?: Criterion;
  activeCriterionValue?: any;
  clearCriteria?: (key?: Key) => void;
  editCriteria?: (key?: Key) => void;
  testId?: string;
  onPopperClose?: () => void;
} & Pick<
  OptionsListProps<OptionItem, OptionValue>,
  'selectedOptions' | 'toggleOption'
> &
  Actions;

export const Search = <
  OptionItem extends unknown,
  OptionValue extends unknown
>({
  rootSx,
  listHeight = 500,
  searchWhileActive = false,
  disableFreeText = false,
  disabled = false,
  chipInputProps = {},
  recentSearchProps,
  criteriaSearchProps,
  dropdownProps,
  autocompleteProps,
  activeCriterion,
  clearCriteria,
  testId,
  inputChipSx,
  onPopperClose,
  editCriteria,
  activeCriterionValue,
  ...props
}: SearchProps<OptionItem, OptionValue>) => {
  const chipInputRef = useRef<ChipInputForwardRef>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [maxListHeight, setMaxListHeight] = useState(listHeight);

  const autocomplete = useDropdownWithAutocomplete({
    dropdownProps,
    autocompleteOverrides: {
      applySortingWhileSearching: true,
      resetInputOnClose: false,
      ...autocompleteProps,
    },
  });
  const inputPropsFromAutocomplete = autocomplete.getters.getInputProps();
  autocomplete.inputRef.current = chipInputRef.current?.inputRef ?? null;

  const showOptions = !!activeCriterion?.key;
  const showPopper =
    !disabled &&
    !!anchorEl &&
    // Either criteria are selected and need to type/select option,
    // or there are recent searches or other criteria to select
    (showOptions ||
      !!recentSearchProps?.recentSearches?.length ||
      !!criteriaSearchProps?.criteria?.length);

  const showNotFound =
    !autocomplete.states.options.length && !searchWhileActive;

  const closePopper = useCallback(() => {
    setAnchorEl(null);
    onPopperClose?.();
  }, [onPopperClose]);

  const openPopper = useCallback(() => {
    if (anchorRef.current && !showPopper) {
      setAnchorEl(anchorRef.current);
    }
  }, [showPopper]);

  const chips = useMemo(() => {
    return chipInputProps?.chips?.map((chipProps) => ({
      ...chipProps,
      onDestroy: (e: MouseEvent) => {
        if (disabled) {
          return;
        }
        e.stopPropagation();
        clearCriteria?.(chipProps.key);
      },
      onClick: () => {
        if (disabled) {
          return;
        }
        editCriteria?.(chipProps.key);
        chipInputRef.current?.inputRef?.focus();
      },
      sx: inputChipSx,
      testId: chipProps.testId ?? 'selected-criteria-chip',
    }));
  }, [
    chipInputProps?.chips,
    clearCriteria,
    inputChipSx,
    editCriteria,
    disabled,
  ]);

  const hideInput =
    disableFreeText &&
    !!anchorEl &&
    !showOptions &&
    !activeCriterion &&
    !chipInputProps?.chips?.length;

  const noFreeText = disableFreeText && !activeCriterion?.key;

  const options = useMemo(() => {
    return dropdownOptionsSort(
      autocomplete.states.options,
      Array.isArray(activeCriterionValue) ? activeCriterionValue : undefined
    );
  }, [autocomplete.states.options, activeCriterionValue]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={closePopper}>
      <Root ref={anchorRef} sx={rootSx} data-testid={testId}>
        <ChipInput
          testId="search-chip-input"
          {...chipInputProps}
          chips={chips}
          inputProps={{
            ...inputPropsFromAutocomplete,
            ...chipInputProps?.inputProps,
            onChange: noFreeText
              ? undefined
              : (event) => {
                  inputPropsFromAutocomplete.onChange(event as any);
                },
            onKeyDown: (event) => {
              const valueIsEmpty =
                (event.target as HTMLInputElement).value === '';
              if (
                event.key === 'Backspace' &&
                activeCriterion &&
                valueIsEmpty
              ) {
                clearCriteria?.(activeCriterion.key);
              }
              if (event.key === 'Escape') {
                if (activeCriterion) {
                  clearCriteria?.(activeCriterion.key);
                } else {
                  closePopper();
                }
                event.stopPropagation();
              }
            },
          }}
          value={inputPropsFromAutocomplete.value}
          onClick={openPopper}
          sx={mergeSx(
            hideInput && {
              opacity: '0',
            },
            noFreeText && {
              '& input': {
                caretColor: 'transparent',
              },
            },
            chipInputProps?.sx
          )}
          disabled={disabled}
          ref={chipInputRef}
        />
        <Popper
          open={showPopper}
          anchorEl={anchorEl}
          sx={(theme) => ({
            boxShadow: 'dropdown',
            padding: '4px 0',
            background: theme.palette.common.white,
            borderRadius: '4px',
            zIndex: theme.zIndex.drawer! + 1,
            width: !!anchorRef.current
              ? anchorRef.current.getBoundingClientRect().width
              : undefined,
          })}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'viewport',
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'viewport',
              },
            },
            {
              name: 'offset',
              enabled: !hideInput,
              options: {
                offset: [0, 4],
              },
            },
            {
              name: 'offset',
              enabled: hideInput,
              options: {
                offset: ({ reference }: any) => {
                  return [0, -reference.height];
                },
              },
            },
            maxSize,
            {
              name: 'applyMaxSize',
              enabled: true,
              phase: 'beforeWrite',
              requires: ['maxSize'],
              fn({ state }) {
                // The `maxSize` modifier provides this data
                const { height } = state.modifiersData.maxSize;

                const maxHeight = Math.min(listHeight, height - 8); // 8px is padding from the viewport edge
                setMaxListHeight(maxHeight - 8); // 8px is the padding of the popper

                state.styles.popper = {
                  ...state.styles.popper,
                  maxHeight: `${maxHeight}px`,
                };
              },
            },
          ]}
          placement="bottom-start"
          componentsProps={{
            root: { 'data-testid': 'search-chip-popper' } as any,
          }}
        >
          {!showOptions && (
            <>
              <CriteriaSearch {...criteriaSearchProps} />
              <RecentSearch {...recentSearchProps} />
            </>
          )}
          <Options
            listHeight={maxListHeight}
            activeCriterion={activeCriterion}
            options={options}
            getOptionProps={autocomplete.getters.getOptionProps}
            selectedOptions={props.selectedOptions}
            toggleOption={props.toggleOption}
            onApply={props.onApply}
            onClearAll={props.onClearAll}
            showOptions={showOptions && !showNotFound}
          />
          {showOptions && showNotFound && (
            <NotFound>
              <Typography variant="P14M" color="grey.900" px="16px">
                No results found
              </Typography>
              <Typography variant="P12R" color="grey.500" px="16px">
                Unfortunately, we did not find any criteria matching “
                {inputPropsFromAutocomplete.value}”.
              </Typography>
            </NotFound>
          )}
        </Popper>
      </Root>
    </ClickAwayListener>
  );
};
