import { Box } from '@mui/material';
import round from 'lodash/round';
import NumberFormat from 'react-number-format';

import { withProps } from '@cast/design-system';

import { MOST_EXPENSIVE_ITEM_COLOR_MAP } from 'common/constants';
import { MetricsBox } from 'components/boxes';

import { useBillingReportContext } from '../../../BillingReportContext';

const StyledMetricsBox = withProps(MetricsBox, {
  sx: { minWidth: 0 },
  markerProps: { minHeight: 48 },
  titleProps: { variant: 'L10R' },
  primaryValueProps: { variant: 'h6' },
  gap: 2,
  secondaryValue: 'CPU',
  secondaryValueProps: { variant: 'h6' },
});

export const ChartSummaries = () => {
  const { totalBillableCpus, topMostExpensiveClusters, showEmptyComponents } =
    useBillingReportContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(6, 1fr)"
      p={16}
      justifyContent="space-between"
      gap={30}
      data-testid="summaries-section"
    >
      <>
        <StyledMetricsBox
          key="total"
          testId="total-billable-cpus"
          title="Billable CPUS"
          primaryValue={
            showEmptyComponents ? (
              '--/--'
            ) : (
              <NumberFormat
                value={round(totalBillableCpus, 2)}
                thousandSeparator
                displayType="text"
              />
            )
          }
          sx={{ minWidth: 130 }}
        />

        {showEmptyComponents &&
          [
            ...MOST_EXPENSIVE_ITEM_COLOR_MAP.colorByUsageRank.slice(0, 4),
            MOST_EXPENSIVE_ITEM_COLOR_MAP.insignificant,
          ].map((color) => (
            <StyledMetricsBox
              testId="empty-metrics"
              key={color}
              markerColor={color}
              primaryValue="--/--"
            />
          ))}

        {!showEmptyComponents &&
          topMostExpensiveClusters.map((cluster, index) => (
            <StyledMetricsBox
              sx={{
                gridColumn: 7 - topMostExpensiveClusters.length + index,
              }}
              key={cluster.name}
              testId={`cluster-cpu-metrics-${index}`}
              markerColor={cluster.color}
              title={cluster.name}
              primaryValue={
                <NumberFormat
                  value={round(cluster.cost, 2)}
                  thousandSeparator
                  displayType="text"
                />
              }
            />
          ))}
      </>
    </Box>
  );
};
